import React from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { State } from '../../../../store';
import RealTimeUpdateEditor from '../../../Common/ReltimeUpdateEditor';

const useStyles = makeStyles((theme) => ({
  captionTitle: {
    margin: theme.spacing(2, 0, 0, 1),
  },
}));

const ResetPasswordMailTemplateEditor: React.FC = () => {
  const classes = useStyles();

  const resetPasswordTemplate = useSelector(
    (state: State) =>
      state.firestore.data.template &&
      state.firestore.data.template.reset_password
  );

  return (
    <>
      <Typography variant="h6" className={classes.captionTitle}>
        変数
      </Typography>
      <ul>
        <li>
          ${'{'}address{'}'} : 追加したユーザーのe-mailアドレス
        </li>
        <li>
          ${'{'}user{'}'} : 追加したユーザーの本名
        </li>
        <li>
          ${'{'}url{'}'} : パスワード変更URL
        </li>
      </ul>
      <RealTimeUpdateEditor
        firestoreDocInfo={{ docPath: 'template/reset_password', field: 'html' }}
        docSelector={resetPasswordTemplate}
        debounceTime={3000}
      />
    </>
  );
};

export default ResetPasswordMailTemplateEditor;
