import React, { useCallback, ReactNode } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';

export type DialogAnswer = 'yes' | 'no' | 'cancel';

type Props<T> = {
  children?: ReactNode;
  isOpen: boolean;
  title: string;
  buttonText?: {
    yes?: string;
    no?: string;
  };
  payload: T;
  onClose: (answer: DialogAnswer, payload: T) => void;
};

const YesNoDialog: <T>(p: Props<T>) => React.ReactElement<Props<T>> = (
  props
) => {
  const handleAnswer = useCallback(
    (answer: DialogAnswer) => (): void => {
      props.onClose(answer, props.payload);
    },
    [props]
  );

  return (
    <Dialog onClose={handleAnswer('cancel')} open={props.isOpen}>
      <DialogTitle>{props.title}</DialogTitle>
      <DialogContent>{props.children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAnswer('yes')}
        >
          {props.buttonText?.yes || 'Yes'}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleAnswer('no')}
        >
          {props.buttonText?.no || 'No'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default YesNoDialog;
