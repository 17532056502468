import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';

import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';

import './App.css';
import { theme } from './theme';
import { Store } from './store';
import { firebaseConfig, authPersistence } from './config';
import Loading from './component/Loading';
import Home from './component/Home';
import PrivateRoute from './component/PrivateRoute';
import SignIn from './component/SignIn';

const reduxFirebase = {
  userProfile: 'users',
  useFirestoreForProfile: true,
  enableLogging: false,
};

// prepare
firebase.initializeApp(firebaseConfig);
firebase.firestore();
firebase.auth().setPersistence(authPersistence);
if (process.env.REACT_APP_USE_LOCAL_FUNCTIONS_SERVER) {
  console.log('use local functions');
  firebase.functions().useFunctionsEmulator('http://localhost:5001');
}

const App: React.FC<{}> = () => {
  return (
    <BrowserRouter>
      <Provider store={Store}>
        <ReactReduxFirebaseProvider
          firebase={firebase}
          config={reduxFirebase}
          dispatch={Store.dispatch}
          createFirestoreInstance={createFirestoreInstance}
        >
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <Loading>
              <Switch>
                <Route path="/signin">
                  <SignIn />
                </Route>
                <Route path="/">
                  <PrivateRoute>
                    <Home />
                  </PrivateRoute>
                </Route>
              </Switch>
            </Loading>
          </ThemeProvider>
        </ReactReduxFirebaseProvider>
      </Provider>
    </BrowserRouter>
  );
};

export default App;
