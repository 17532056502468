import React, { useState, useCallback } from 'react';
import {
  makeStyles,
  Typography,
  Box,
  AppBar,
  Tabs,
  Tab,
  Paper,
} from '@material-ui/core';
import HUE from '@material-ui/core/colors/purple';
import AddUserMailTemplateEditor from './AddUserMailTemplateEditor';
import ResetPasswordMailTemplateEditor from './ResetPasswordMailTemplateEditor';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: theme.spacing(2, 0),
  },
  tabIndicator: {
    height: 3,
    backgroundColor: HUE[200],
  },
  config: {
    //border: 'solid',
  },
  editor: {
    margin: theme.spacing(2, 2),
    //padding: 0,
    minHeight: 300,
    fontFamily: theme.typography.fontFamily,
    background: theme.palette.background.paper,
  },

  captionTitle: {
    margin: theme.spacing(2, 0, 0, 1),
  },
}));

const UserManageConfig: React.FC = () => {
  const classes = useStyles();
  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = useCallback(
    (event: React.ChangeEvent<{}>, index: number) => {
      setTabIndex(index);
    },
    []
  );

  return (
    <Box className={classes.title}>
      <Typography variant="h5">設定</Typography>
      <Paper className={classes.config}>
        <AppBar position="static" color="secondary">
          <Tabs
            classes={{ indicator: classes.tabIndicator }}
            indicatorColor="primary"
            value={tabIndex}
            variant="fullWidth"
            onChange={handleTabChange}
          >
            <Tab label="ユーザー登録完了通知メール" />
            <Tab label="パスワードリセット通知メール" />
          </Tabs>
        </AppBar>
        {tabIndex === 0 && <AddUserMailTemplateEditor />}
        {tabIndex === 1 && <ResetPasswordMailTemplateEditor />}
      </Paper>
    </Box>
  );
};

export default UserManageConfig;
