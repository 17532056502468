import { actionCreatorFactory } from 'typescript-fsa';

const actionCreator = actionCreatorFactory('ACCOUNT');

export const SignOut = actionCreator<void>('SIGN_OUT');

export const SignIn = actionCreator.async<
  { name: string; password: string },
  {},
  void
>('SIGN_IN');
