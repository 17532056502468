import { Reducer } from 'redux';
import { isType } from 'typescript-fsa';
import { produce } from 'immer';

import { Account } from '..';
import { SignIn, SignOut } from '..';

const initialState: Account = {};

export const reducer: Reducer<Account> = (state = initialState, action) => {
  if (isType(action, SignIn.started)) {
    return produce(state, (draft) => {
      //draft = {id: "hoi", name: "hoi"};
      console.log(draft.id);
      draft.id = undefined;
    });
  }
  if (isType(action, SignIn.done)) {
    return produce(state, (draft) => {
      draft.id = action.payload.params.name;
    });
  }

  if (isType(action, SignOut)) {
    return produce(state, () => {
      state = {};
    });
  }

  return state;
};

export default reducer;
