import React, { ReactElement } from 'react';
import PersonIcon from '@material-ui/icons/Person';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import WorkIcon from '@material-ui/icons/Work';
import CreateIcon from '@material-ui/icons/Create';
import ImportContactsIcon from '@material-ui/icons/ImportContacts';
import SettingsIcon from '@material-ui/icons/Settings';

import DummyContents from './DummyContents';
import NotFound from './NotFound';
import UserManage from './UserManage';
import GroupManage from './GroupManage';

export type MainContentsProps = {
  uid: string;
  isAdmin: boolean;
  setTitle: (title: string) => void;
  setStatusBarText: (title: string) => void;
};
export type MainContentsComponent = React.FC<MainContentsProps>;

type SideMenuInfo = {
  isDivider: boolean;
  nest: number;
  icon: ReactElement | undefined;
  displayName: string | undefined;
};

type ContentInfo = {
  key: string | undefined;
  menu: SideMenuInfo;
  url: string;
  component: MainContentsComponent | undefined;
};

const createComponentInfo = (
  isDivider: boolean,
  nest: number | undefined = undefined,
  key: string | undefined = undefined,
  displayName: string | undefined = undefined,
  icon: ReactElement | undefined = undefined,
  component: MainContentsComponent | undefined = undefined,
  url: string | undefined = undefined
): ContentInfo => {
  return {
    key: key,
    menu: {
      isDivider: isDivider,
      nest: nest ? nest : 0,
      displayName: displayName,
      icon: icon,
    },
    url: url ? url : key ? '/' + key : '',
    component: component,
  };
};

export const Contents: ContentInfo[] = [
  // work report
  createComponentInfo(
    false,
    0,
    'workreport',
    '作業報告',
    <WorkIcon />,
    undefined
  ),
  createComponentInfo(
    false,
    1,
    'read_wrok_report',
    '読む',
    <ImportContactsIcon />,
    DummyContents
  ),
  createComponentInfo(
    false,
    1,
    'write_wrok_report',
    '書く',
    <CreateIcon />,
    DummyContents
  ),
  createComponentInfo(
    false,
    1,
    'config_wrok_report',
    '設定',
    <SettingsIcon />,
    DummyContents
  ),
  createComponentInfo(true),

  // manage
  createComponentInfo(
    false,
    0,
    'manage',
    '管理',
    <EmojiPeopleIcon />,
    undefined
  ),
  createComponentInfo(
    false,
    1,
    'add_user',
    'ユーザー',
    <PersonAddIcon />,
    UserManage
  ),
  createComponentInfo(
    false,
    1,
    'add_group',
    'グループ',
    <GroupAddIcon />,
    GroupManage
  ),
  createComponentInfo(true),

  // profile
  createComponentInfo(
    false,
    0,
    'profile',
    'プロファイル',
    <PersonIcon />,
    DummyContents
  ),

  // not found
  createComponentInfo(
    false,
    0,
    'notfound',
    undefined,
    undefined,
    NotFound,
    '/'
  ),
];
