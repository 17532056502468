import React, { useCallback } from 'react';
import { State } from '../../store';

import { useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import { isLoaded } from 'react-redux-firebase';

const LoadScreen: React.FC = () => {
  return <div>loading...</div>;
};

const Loading: React.FC = ({ children }) => {
  const auth = useSelector((state: State) => state.firebase.auth);
  const loadingRender = useCallback(() => {
    return !isLoaded(auth) ? <LoadScreen /> : children;
  }, [auth, children]);

  return <Route render={loadingRender} />;
};

export default Loading;
