import { createMuiTheme } from '@material-ui/core/styles';
import purple from '@material-ui/core/colors/purple';

export const theme = createMuiTheme({
  palette: {
    type: 'dark',
    primary: purple,
    secondary: {
      main: purple['800'],
    },
  },
  typography: {
    fontFamily: ['Noto Sans Japanese', 'Roboto'].join(','),
    fontWeightLight: 100,
    fontWeightRegular: 300,
    fontSize: 14,
  },
});
