import { useSelector } from 'react-redux';
import { State } from '../../store';
import React from 'react';
import { isLoaded, isEmpty } from 'react-redux-firebase';
import { Redirect, Route } from 'react-router-dom';

const PrivateRoute: React.FC = ({ children, ...rest }) => {
  const auth = useSelector((state: State) => state.firebase.auth);

  return (
    <Route
      {...rest}
      render={({ location }): JSX.Element => {
        if (isLoaded(auth) && !isEmpty(auth)) {
          return <>{children}</>;
        } else {
          return (
            <Redirect
              to={{
                pathname: '/signin',
                state: { from: location },
              }}
            />
          );
        }
      }}
    ></Route>
  );
};

export default PrivateRoute;
