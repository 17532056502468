import React, { useState, useEffect, useCallback } from 'react';
import { useTheme, Typography } from '@material-ui/core';
import { useFirestoreConnect, isLoaded } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { State } from '../../../store';
import MaterialTable, { Localization, Options } from 'material-table';
import * as firebase from 'firebase/app';
import 'firebase/functions';
import './UserManageConfig';
import UserManageConfig from './UserManageConfig';
import { MainContentsComponent } from '../componentmap';
import YesNoDialog, { DialogAnswer } from '../../Common/YesNoDialog';

const localization: Localization = {
  header: {
    actions: '操作',
  },
  body: {
    editRow: {
      deleteText: 'このユーザーを本当に削除しますか?',
    },
    editTooltip: '編集',
    deleteTooltip: '削除',
  },
};

const options: Options = {
  search: true,
  actionsColumnIndex: -1,
};

type UserData = {
  uid: string;
  // legitimateUsers
  realName: string;
  email: string;
  // users
  displayName: string;
};

export type Props = {
  setTitle: (title: string) => void;
};

const UserManage: MainContentsComponent = (props) => {
  const [titile] = useState('ユーザー管理');
  const [isLoading, setLoading] = useState(true);
  const [userDatas, setUserDatas] = useState<UserData[]>([]);
  const theme = useTheme();

  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useFirestoreConnect('legitimate_users');
  useFirestoreConnect('users');

  const legitimateUsers = useSelector(
    (state: State) => state.firestore.ordered.legitimate_users
  );
  const users = useSelector((state: State) => state.firestore.ordered.users);

  useEffect(() => {
    if (isLoaded(legitimateUsers) && isLoaded(users)) {
      const validUsersMap = new Map<string, UserData>();
      for (const data of legitimateUsers) {
        validUsersMap.set(data.id, {
          uid: data.id,
          realName: data.realName,
          email: data.email,
          displayName: '',
        });
      }
      for (const data of users) {
        const tmp = validUsersMap.get(data.id);
        if (tmp) {
          tmp.displayName = data.displayName;
          validUsersMap.set(data.id, tmp);
        }
      }
      const validUserData: UserData[] = [];
      validUsersMap.forEach((value: UserData) => {
        validUserData.push(value);
      });
      setUserDatas(validUserData);
      setLoading(false);
    }
  }, [users, legitimateUsers]);

  useEffect(() => {
    props.setTitle(titile);
  }, [props, titile]);

  const onAdd = async (
    newData: UserData
  ): Promise<firebase.functions.HttpsCallableResult> => {
    const f = firebase.functions().httpsCallable('addUser')({
      email: newData.email,
      realName: newData.realName,
      displayName: newData.displayName,
    });
    f.then(() => {
      console.log('done');
    });

    return f;
  };

  const onDelete = async (
    target: UserData
  ): Promise<firebase.functions.HttpsCallableResult> => {
    const f = firebase.functions().httpsCallable('deleteUser')({
      uid: target.uid,
    });
    f.catch((reason) => {
      console.log(`error: ${reason}`);
    });

    return f;
  };

  const [resetPasswordTarget, setResetPasswordTarget] = useState<UserData>();
  const [resetPasswordText, setResetPasswordText] = useState('');
  const handleResetPasswordClick = useCallback(
    (event: React.MouseEvent, target: UserData | UserData[]) => {
      if (!Array.isArray(target)) {
        setResetPasswordTarget(target);
        setResetPasswordText(
          `${target.realName}さんのパスワードをリセットしますか？`
        );
        setIsDialogOpen(true);
      } else {
        // error
      }
    },
    []
  );

  const handleOnCloseDialog = async (
    answer: DialogAnswer,
    payload: UserData | undefined
  ): Promise<firebase.functions.HttpsCallableResult | void> => {
    if (answer === 'yes') {
      const f = firebase
        .functions()
        .httpsCallable('resetPassword')({
          email: (payload as UserData).email,
          realName: (payload as UserData).realName,
        })
        .catch((reason) => {
          console.log(`error: ${reason}`);
        });

      setIsDialogOpen(false);
      setLoading(true);

      setTimeout(() => {
        setLoading(false);
      }, 2000);

      return f;
    } else {
      setIsDialogOpen(false);
    }
  };

  return (
    <>
      <MaterialTable
        title="ユーザー 一覧"
        columns={[
          { title: '表示名', field: 'displayName' },
          { title: '名前', field: 'realName' },
          { title: 'メールアドレス', field: 'email' },
          {
            title: 'UID',
            field: 'uid',
            cellStyle: {
              textAlign: 'left',
              fontSize: theme.typography.fontSize - 6,
            },
            editable: 'never',
          },
        ]}
        localization={localization}
        data={userDatas}
        options={options}
        isLoading={isLoading}
        editable={
          props.isAdmin
            ? {
                onRowAdd: onAdd,
                onRowDelete: onDelete,
              }
            : {}
        }
        actions={
          props.isAdmin
            ? [
                {
                  icon: 'vpn_key',
                  tooltip: 'パスワードリセット',
                  onClick: handleResetPasswordClick,
                },
              ]
            : []
        }
      />
      {props.isAdmin && (
        <>
          <UserManageConfig />
          <YesNoDialog
            isOpen={isDialogOpen}
            title={'パスワードリセット確認'}
            buttonText={{ yes: '送信', no: '取り消し' }}
            payload={resetPasswordTarget}
            onClose={handleOnCloseDialog}
          >
            <Typography variant="body1">{resetPasswordText}</Typography>
          </YesNoDialog>
        </>
      )}
    </>
  );
};

export default UserManage;
