import { combineReducers, createStore, Reducer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { firebaseReducer, FirebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

import { Account } from './account';
import accountREducer from './account';
import FireStoreState from './firestore';

type UserProfile = {
  displayName: string;
  email: string;
};

type RealtimeDBRecord = {};

type StateType = {
  account: Account;
  firebase: FirebaseReducer.Reducer<UserProfile, RealtimeDBRecord>;
  firestore: FireStoreState;
};

const reducers = combineReducers<StateType>({
  account: accountREducer,
  firebase: firebaseReducer,
  firestore: firestoreReducer as Reducer<FireStoreState>,
});

const composeEnhancers = composeWithDevTools({});
export const Store = createStore(reducers, composeEnhancers());

export type State = ReturnType<typeof Store.getState>;
