import { MainContentsComponent } from '../componentmap';
import React, { useState, useCallback, useEffect } from 'react';
import MaterialTable, { Options } from 'material-table';
import { useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { State } from '../../../store';
import {
  useFirestoreConnect,
  isLoaded,
  useFirestore,
} from 'react-redux-firebase';
import GroupMembersTable from './GroupMembersTable';

type GroupListColumns = {
  groupName: string;
  groupId: string;
};

const groupListOptions: Options = {
  search: true,
  actionsColumnIndex: -1,
};

const GroupManage: MainContentsComponent = (props) => {
  const theme = useTheme();
  const firestore = useFirestore();
  const [isLoading, setIsLoading] = useState(true);
  const [groupData, setGroupData] = useState<GroupListColumns[]>(
    [] as GroupListColumns[]
  );
  const [selectedRow, setSelectedRow] = useState<GroupListColumns>();

  // Groups
  useFirestoreConnect([
    { collection: 'groups' },
    { collection: 'legitimate_users' },
  ]);
  const groups = useSelector((state: State) => state.firestore.ordered.groups);
  const users = useSelector(
    (state: State) => state.firestore.ordered.legitimate_users
  );
  useEffect(() => {
    if (isLoaded(groups)) {
      setIsLoading(false);
    }
    if (groups) {
      setGroupData(
        groups &&
          groups.map((group) => {
            return {
              groupId: group.id || '-',
              groupName: group.name || '-',
            };
          })
      );
    }
  }, [groupData.length, groups, users]);

  const setSelectedColor = useCallback(
    (rowData?: GroupListColumns) => {
      return {
        backgroundColor:
          selectedRow && rowData && selectedRow.groupId === rowData.groupId
            ? theme.palette.action.selected
            : theme.palette.background.paper,
      };
    },
    [selectedRow, theme.palette.action.selected, theme.palette.background.paper]
  );

  const handleOnRowClick = useCallback(
    (
      event?: React.MouseEvent<Element, MouseEvent>,
      rowData?: GroupListColumns
    ) => {
      setSelectedRow(rowData);
    },
    []
  );

  const handleOnAdd = useCallback(
    async (newData: GroupListColumns) => {
      if (newData.groupName) {
        await firestore
          .collection('groups')
          .add({ name: newData.groupName, createdBy: props.uid });
      }
    },
    [firestore, props.uid]
  );

  const handleOnDelete = useCallback(
    async (oldData: GroupListColumns) => {
      if (oldData.groupId) {
        await firestore.doc(`groups/${oldData.groupId}`).delete();
      }
    },
    [firestore]
  );

  return (
    <>
      <MaterialTable<GroupListColumns>
        title="グループ 一覧"
        columns={[
          {
            title: 'グループID',
            field: 'groupId',
            cellStyle: { fontFamily: 'Roboto Mono' },
          },
          { title: 'グループ名', field: 'groupName' },
        ]}
        //localization={localization}
        data={groupData}
        options={{ ...groupListOptions, rowStyle: setSelectedColor }}
        isLoading={isLoading}
        onRowClick={handleOnRowClick}
        editable={{ onRowAdd: handleOnAdd, onRowDelete: handleOnDelete }}
      />
      <GroupMembersTable
        groupId={selectedRow?.groupId}
        groupName={selectedRow?.groupName}
        {...props}
      />
    </>
  );
};

export default GroupManage;
