import React, { useCallback } from 'react';
import {
  makeStyles,
  useTheme,
  Theme,
  createStyles,
} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { Contents } from './../Home/componentmap';
import { Grid, Typography } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';

export type Props = {
  width: number;
  isOpen: boolean;
  onClose: () => void;
};

const MenuItems: React.FC<{ onClose: () => void }> = (props) => {
  const theme = useTheme();

  const renderLink = useCallback(
    (to: string) => {
      const component = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>(
        (itemProps, ref) => (
          <RouterLink
            to={to}
            ref={ref}
            {...itemProps}
            onClick={props.onClose}
          />
        )
      );
      component.displayName = 'RenderLink';
      return component;
    },
    [props.onClose]
  );

  const refDiv = useCallback(() => {
    const component = React.forwardRef<any, {}>((props, ref) => (
      <div ref={ref} {...props} />
    ));
    component.displayName = 'RefDiv';
    return component;
  }, []);

  return (
    <>
      {Contents.map((info, index) => {
        return info.menu.isDivider ? (
          <Divider key={index} />
        ) : info.menu.displayName ? (
          <ListItem
            style={{ paddingLeft: theme.spacing((info.menu.nest + 1) * 2) }}
            button={info.component ? (true as any) : false}
            key={info.key}
            component={info.component ? renderLink(info.url) : refDiv()}
          >
            {info.menu.icon ? (
              <ListItemIcon>{info.menu.icon}</ListItemIcon>
            ) : null}
            <ListItemText primary={info.menu.displayName} />
          </ListItem>
        ) : null;
      })}
    </>
  );
};

const SideMenu: React.FC<Props> = (props) => {
  const classes = makeStyles((theme: Theme) =>
    createStyles({
      drawer: {
        width: props.width,
        flexShrink: 0,
      },
      drawerPaper: {
        width: props.width,
      },
      drawerHeader1: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 2),
        ...theme.mixins.toolbar,
      },
      drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        justifyContent: 'flex-end',
        ...theme.mixins.toolbar,
      },
      menuIcon: {
        display: 'flex',
        alignItems: 'center',
        marginLeft: theme.spacing(),
        flexShrink: 0,
        ...theme.mixins.toolbar,
      },
    })
  )();

  return (
    <Drawer
      className={classes.drawer}
      anchor="left"
      open={props.isOpen}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Grid container direction="row" justify="flex-start" alignItems="center">
        <Grid item xs={3} className={classes.menuIcon}>
          <IconButton onClick={props.onClose}>
            <MenuIcon />
          </IconButton>
        </Grid>
        <Grid item xs>
          <Typography variant="h4">App</Typography>
        </Grid>
      </Grid>
      <Divider />
      <List>
        <MenuItems onClose={props.onClose} />
      </List>
    </Drawer>
  );
};

export default SideMenu;
