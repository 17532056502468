import React, { useCallback } from 'react';
import clsx from 'clsx';
import {
  makeStyles,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import { useFirebase } from 'react-redux-firebase';
import MenuIcon from '@material-ui/icons/Menu';

export type Props = {
  title: string;
  sideMenuWidth: number;
  isSideMenuOpen: boolean;
  onSideMenuOpen: (event: React.MouseEvent) => void;
};

const TitleBar: React.FC<Props> = (props) => {
  const firebase = useFirebase();

  const classes = makeStyles((theme) => ({
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      //width: `calc(100% - ${props.sideMenuWidth}px)`,
      //marginLeft: props.sideMenuWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    sideMenuButton: {
      margin: theme.spacing(0, 2, 0, -2),
    },
    sideMenuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
  }))();

  const onSignOut = useCallback(() => {
    firebase.logout();
  }, [firebase]);

  return (
    <AppBar
      className={clsx(classes.appBar, {
        [classes.appBarShift]: props.isSideMenuOpen,
      })}
      position="static"
    >
      <Toolbar>
        <IconButton
          className={clsx(classes.sideMenuButton, props.isSideMenuOpen)}
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={props.onSideMenuOpen}
        >
          <MenuIcon />
        </IconButton>
        <Typography variant="subtitle1" className={classes.title}>
          {props.title}
        </Typography>
        <Button color="inherit" variant="outlined" onClick={onSignOut}>
          Sign out
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default TitleBar;
