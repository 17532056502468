import React, { useState, useCallback } from 'react';
import clsx from 'clsx';
import { Box, makeStyles } from '@material-ui/core';
import { Route, Switch } from 'react-router-dom';
import SideMenu, { Props as SideMenuProps } from '../SideMenu';
import TitleBar, { Props as TitleBarProps } from '../TitleBar';
import { Contents, MainContentsProps } from './componentmap';
import { useFirestoreConnect } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { State } from '../../store';

const sideMenuWidth = 200;

const useStyles = makeStyles((theme) => ({
  content: {
    flexGrow: 1,
    margin: theme.spacing(2),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    //marginLeft: sideMenuWidth + Number(theme.spacing(2)),
  },
}));

const CreateMainComponent: React.FC<MainContentsProps> = (props) => {
  return (
    <Switch>
      {Contents.map((info) => {
        return !info.menu.isDivider && info.component !== undefined ? (
          <Route key={info.key} path={info.url}>
            {React.createElement(info.component, {
              key: info.key,
              uid: props.uid,
              isAdmin: props.isAdmin,
              setTitle: props.setTitle,
              setStatusBarText: props.setStatusBarText,
            })}
          </Route>
        ) : null;
      })}
    </Switch>
  );
};

const Home: React.FC = () => {
  const classes = useStyles();
  const [isSideMnuOpen, setSideMenuOpen] = useState(false);
  const [title, setTitle] = useState('');

  const onSetTitle = useCallback((title: string) => {
    setTitle(title);
  }, []);

  const SideMenuOpenClose = (isOpen: boolean): (() => void) =>
    useCallback(() => {
      setSideMenuOpen(isOpen);
    }, [isOpen]);

  const sideMenuProps: SideMenuProps = {
    isOpen: isSideMnuOpen,
    onClose: SideMenuOpenClose(false),
    width: sideMenuWidth,
  };

  const titleBarProps: TitleBarProps = {
    title: title,
    onSideMenuOpen: SideMenuOpenClose(true),
    isSideMenuOpen: isSideMnuOpen,
    sideMenuWidth: sideMenuProps.width,
  };

  const uid = useSelector((state: State) => state.firebase.auth.uid);
  useFirestoreConnect({
    collection: 'groups/admin/members',
    storeAs: 'groups/admin/members',
  });
  const isAdmin = useSelector(
    (state: State) =>
      (uid &&
        state.firestore.ordered['groups/admin/members'] &&
        state.firestore.ordered['groups/admin/members'].some(
          (value) => value.id === uid
        )) ||
      false
  );

  return (
    <>
      <TitleBar {...titleBarProps} />
      <SideMenu {...sideMenuProps} />
      <Box
        className={clsx(classes.content, {
          [classes.contentShift]: isSideMnuOpen,
        })}
      >
        {CreateMainComponent({
          uid: uid,
          isAdmin: isAdmin,
          setTitle: onSetTitle,
          setStatusBarText: onSetTitle,
        })}
      </Box>
    </>
  );
};

export default Home;
