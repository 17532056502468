import * as firebase from 'firebase/app';
import 'firebase/auth';

export const firebaseConfig = {
  apiKey: 'AIzaSyAHZ1yUFP3DizEIdeWyViypa3Qfu5_FrWA',
  authDomain: 'rise-aa6c7.firebaseapp.com',
  databaseURL: 'https://rise-aa6c7.firebaseio.com',
  projectId: 'rise-aa6c7',
  storageBucket: 'rise-aa6c7.appspot.com',
  messagingSenderId: '338161384967',
  appId: '1:338161384967:web:b9d3b52f1a7bd034d374a2',
};

// 認証永続性
export const authPersistence = firebase.auth.Auth.Persistence.LOCAL;
