import React, { useEffect } from 'react';
import { Container, Typography, makeStyles, Grid } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { MainContentsComponent } from '../componentmap';

const useStyles = makeStyles((theme) => ({
  content: {
    //flexGrow: 1,
    margin: theme.spacing(1, 0),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));

export type Props = {
  setTitle: (title: string) => void;
};

const DummyContents: MainContentsComponent = (props) => {
  const location = useLocation();
  const classes = useStyles();

  useEffect(() => {
    props.setTitle(location.pathname);
  }, [props, location.pathname]);

  return (
    <Container maxWidth="xs" className={classes.content}>
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          <Typography variant="h2">
            Dummy
            {props.children ? props.children : ''}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DummyContents;
