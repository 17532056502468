import React, { useState, useCallback, useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import {
  useFirestore,
  useFirestoreConnect,
  isLoaded,
  isEmpty,
} from 'react-redux-firebase';
import RichTextEditor, { EditorValue } from 'react-rte';
import { useDebouncedCallback } from 'use-debounce/lib';

const useStyles = makeStyles((theme) => ({
  editor: {
    margin: theme.spacing(2, 2),
    //padding: 0,
    minHeight: 300,
    fontFamily: theme.typography.fontFamily,
    background: theme.palette.background.paper,
  },
}));

type Props = {
  firestoreDocInfo: {
    docPath: string;
    field: string;
  };
  docSelector: { [key: string]: string };
  debounceTime: number;
};

const RealTimeUpdateEditor: React.FC<Props> = (props) => {
  const classes = useStyles();
  const firestore = useFirestore();
  const [editorValue, setEditorValue] = useState(
    RichTextEditor.createEmptyValue()
  );

  useFirestoreConnect(props.firestoreDocInfo.docPath);

  useEffect(() => {
    if (
      isLoaded(props.docSelector) &&
      !isEmpty(props.docSelector) &&
      !isEmpty(props.docSelector[props.firestoreDocInfo.field]) &&
      !editorValue.getEditorState().getCurrentContent().hasText()
    ) {
      setEditorValue(
        RichTextEditor.createValueFromString(
          props.docSelector[props.firestoreDocInfo.field],
          'html'
        )
      );
    }
  }, [props, editorValue]);

  const [updateTemplate] = useDebouncedCallback<string[]>((value) => {
    const data = {} as { [key: string]: string };
    data[props.firestoreDocInfo.field] = value;
    if (!isEmpty(props.docSelector)) {
      firestore
        .doc(props.firestoreDocInfo.docPath)
        .update(data)
        .catch((reason) => {
          /* error */
          console.error(reason);
        });
    } else {
      firestore
        .doc(props.firestoreDocInfo.docPath)
        .set(data)
        .catch((reason) => {
          /* error */
          console.error(reason);
        });
    }
  }, props.debounceTime);

  const handleOnChange = useCallback(
    (value: EditorValue) => {
      setEditorValue(value);
      updateTemplate(value.toString('html'));
    },
    [updateTemplate]
  );

  return (
    <RichTextEditor
      readOnly={!isLoaded(props.docSelector)}
      className={classes.editor}
      value={editorValue}
      onChange={handleOnChange}
    />
  );
};

export default RealTimeUpdateEditor;
