import { theme } from '../../theme';
import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import 'firebase/auth';
import 'firebase/firestore';

import {
  Button,
  TextField,
  Box,
  Container,
  Card,
  CardContent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  useFirebase,
  useFirestoreConnect,
  ReduxFirestoreQuerySetting,
  isEmpty,
  isLoaded,
} from 'react-redux-firebase';
import { State } from '../../store';
import { useLocation, Redirect } from 'react-router-dom';

const useStyles = makeStyles({
  root: {
    height: '100%',
    paddingTop: 5,
  },
  paper: {
    display: 'flex',
  },
  form: {},
  submit: {
    margin: theme.spacing(4, 0, 10, 0),
  },
  card: {
    maxWidth: 275,
    alignItems: 'center',
    //display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(1.0)',
  },
});

const SignIn: React.FC<{}> = () => {
  const [isSubmitButtonEnable, setSubmitButtonEnable] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [redirect, setRedirect] = useState('');
  const location = useLocation<{ from: Location | null | undefined }>();

  const classes = useStyles();
  const firebase = useFirebase();

  const user = useSelector((state: State) => {
    return state.firebase.auth;
  });

  const profile = useSelector((state: State) => {
    return state.firebase.profile;
  });

  const todosQuery: ReduxFirestoreQuerySetting = {
    collection: 'hogehoge3',
    limit: 10,
  };

  useFirestoreConnect(() => [todosQuery]);

  const signIn = useCallback(
    (e: React.ChangeEvent<HTMLFormElement>) => {
      e.preventDefault();
      setSubmitButtonEnable(false);
      firebase.login({ email: email, password: password }).finally(() => {
        setSubmitButtonEnable(true);
      });
      //if (todosQuery.collection){
      //  firestore.collection(todosQuery.collection).add({hoged:'111data'});
      //}
      //firestore.collection('hogehoge3').doc('5').set({hoge:'data'});
      //todosQuery.collection = '';
    },
    [firebase, email, password]
  );

  const handleChangeEmail = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setEmail(e.target.value);
    },
    []
  );

  const handleChangePassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setPassword(e.target.value);
    },
    []
  );

  useEffect(() => {
    if (isLoaded(user) && !isEmpty(user)) {
      if (location.state?.from?.pathname) {
        setRedirect(location.state.from.pathname);
      } else {
        setRedirect('/');
      }
    }
  }, [user, location.state]);

  return (
    <>
      {redirect ? (
        <Redirect
          to={{
            pathname: redirect,
            state: { from: location },
          }}
        />
      ) : null}
      <Box>
        <Container maxWidth="xs">
          <div className={classes.paper}>
            <form className={classes.form} onSubmit={signIn}>
              <TextField
                name="username"
                margin="normal"
                fullWidth
                label="メールアドレス"
                autoComplete="username"
                value={email}
                onChange={handleChangeEmail}
              />
              <TextField
                name=""
                margin="normal"
                fullWidth
                label="パスワード"
                type="password"
                autoComplete="current-password"
                value={password}
                onChange={handleChangePassword}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={!isSubmitButtonEnable}
                className={classes.submit}
              >
                SignIn
              </Button>
            </form>
          </div>
        </Container>
      </Box>

      <Box>
        <Container maxWidth="xs">
          {location.state.from?.pathname}
          <Card className={classes.card}>
            <CardContent>
              <div className={classes.paper}>
                <div>{user.email}</div>
                <div>{profile.isLoaded ? 'ok' : 'ng'}</div>
              </div>
            </CardContent>
          </Card>
        </Container>
      </Box>
    </>
  );
};

export default SignIn;
