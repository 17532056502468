import React, { useState, useEffect, useCallback } from 'react';
import MaterialTable, { Options } from 'material-table';
import { useSelector } from 'react-redux';
import { State } from '../../../store';
import {
  useFirestoreConnect,
  useFirestore,
  isLoaded,
} from 'react-redux-firebase';
import { legitimateUsers } from '../../../store/firestore';
import { MainContentsProps } from '../componentmap';
import { Typography, Tooltip } from '@material-ui/core';

import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

type MemberListColumns = {
  userName: string;
  userId: string;
  role: 'owner' | 'member';
};

const userListOptions: Options = {
  actionsColumnIndex: -1,
};

export type Props = MainContentsProps & {
  groupId?: string;
  groupName?: string;
};

const GroupMembersTable: React.FC<Props> = (props) => {
  // Context
  const firestore = useFirestore();

  // State
  const [isLoading, setIsLoading] = useState(false);
  const [memberListTitle, setMemberListTitle] = useState<string>('');
  const [currentGroup, setCurrentGroup] = useState<string>();
  const [memberData, setMemberData] = useState<MemberListColumns[]>(
    [] as MemberListColumns[]
  );

  // Listener
  useFirestoreConnect([{ collection: 'legitimate_users' }]);

  // Selector
  const users = useSelector(
    (state: State) => state.firestore.ordered.legitimate_users
  );
  const members = useSelector(
    (state: State) => state.firestore.ordered.groupMembers
  );
  const owners = useSelector(
    (state: State) => state.firestore.ordered.groupOwners
  );

  // Function
  useEffect(() => {
    if (props.groupId !== currentGroup) {
      if (currentGroup) {
        firestore.unsetListeners([
          {
            collection: `groups/${currentGroup}/members`,
            storeAs: 'groupMembers',
          },
          {
            collection: `groups/${currentGroup}/owners`,
            storeAs: 'groupOwners',
          },
        ]);
      }
      firestore.setListeners([
        {
          collection: `groups/${props.groupId}/members`,
          storeAs: 'groupMembers',
        },
        {
          collection: `groups/${props.groupId}/owners`,
          storeAs: 'groupOwners',
        },
      ]);
      setCurrentGroup(props.groupId);
      setMemberListTitle(`${props.groupName} メンバー一覧`);
      setIsLoading(true);
    }
  }, [currentGroup, firestore, props.groupId, props.groupName]);

  useEffect(() => {
    if (isLoaded(owners) && isLoaded(members)) {
      let data = [] as MemberListColumns[];
      if (owners) {
        data = data.concat(
          owners.map<MemberListColumns>((owner) => {
            return {
              userId: owner.id,
              userName:
                users?.find((value: legitimateUsers) => {
                  return value.id === owner.id;
                })?.realName || '-',
              role: 'owner',
            };
          })
        );
      }
      if (members) {
        data = data.concat(
          members.map<MemberListColumns>((member) => {
            return {
              userId: member.id || '-',
              userName:
                users?.find((value: legitimateUsers) => {
                  return value.id === member.id;
                })?.realName || '-',
              role: 'member',
            };
          })
        );
      }
      if (data) {
        setMemberData(data);
      }
      setIsLoading(false);
    }
  }, [members, owners, users]);

  const handleOnAdd = useCallback(
    (newData: MemberListColumns) => {
      if (newData.userId) {
        return firestore
          .collection(`groups/${currentGroup}/members`)
          .doc(newData.userId)
          .set({});
      } else {
        return new Promise<void>((resolve) => {
          resolve();
        });
      }
    },
    [currentGroup, firestore]
  );

  const handleOnDelete = useCallback(
    (newData: MemberListColumns) => {
      if (newData.userId) {
        return firestore
          .collection(`groups/${currentGroup}/members`)
          .doc(newData.userId)
          .delete();
      } else {
        return new Promise<void>((resolve) => {
          resolve();
        });
      }
    },
    [currentGroup, firestore]
  );

  const roleRender = useCallback((data: MemberListColumns) => {
    if (!data || !data.role) {
      return;
    }
    return (
      <>
        {data.role === 'owner' ? (
          <Tooltip title="オーナー" aria-label="owner">
            <SupervisedUserCircleIcon fontSize="large" />
          </Tooltip>
        ) : (
          <Tooltip title="メンバー" aria-label="owner">
            <AccountCircleIcon fontSize="large" />
          </Tooltip>
        )}
      </>
    );
  }, []);

  // Render
  return (
    <>
      <MaterialTable<MemberListColumns>
        title={memberListTitle}
        columns={[
          {
            title: 'ユーザーID',
            field: 'userId',
            cellStyle: { fontFamily: 'Roboto Mono' },
          },
          { title: 'ユーザー名', field: 'userName', editable: 'never' },
          {
            title: '役割',
            field: 'role',
            editable: 'never',
            render: roleRender,
          },
        ]}
        //localization={localization}
        data={memberData}
        options={userListOptions}
        isLoading={isLoading}
        editable={{
          onRowAdd: handleOnAdd,
          onRowDelete: handleOnDelete,
        }}
      />
    </>
  );
};

export default GroupMembersTable;
